import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { additionalOptions } from 'src/locales/constant/arrayRender';
import { CustomTableCellSmall } from 'src/pages/dashboard/CustomComponents/Custom';

function ConfirmOptionsTable(props) {
   const { data } = props;

   return (
      <Table>
         <TableHead>
            <TableRow>
               <CustomTableCellSmall align='center'>Options</CustomTableCellSmall>
               <CustomTableCellSmall align='center'>Price</CustomTableCellSmall>
            </TableRow>
         </TableHead>

         <TableBody>
            {additionalOptions.map((option) => (
               <React.Fragment key={option.field}>
                  {data?.config_notice?.[option.field] && (
                     <TableRow>
                        <CustomTableCellSmall align='center' width='80%'>
                           {option.name}
                        </CustomTableCellSmall>
                        <CustomTableCellSmall align='center' width='20%'>
                           {data?.order?.options[option.field] || 0}
                        </CustomTableCellSmall>
                     </TableRow>
                  )}
               </React.Fragment>
            ))}
         </TableBody>
      </Table>
   );
}

export default ConfirmOptionsTable;

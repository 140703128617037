import { useCallback } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import User03Icon from '@untitled-ui/icons-react/build/esm/User03';
import {
   Box,
   Button,
   Divider,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   Popover,
   SvgIcon,
   Typography,
} from '@mui/material';
import { RouterLink } from 'src/components/router-link';
import { useAuth } from 'src/hooks/use-auth';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import { Issuer } from 'src/utils/auth';
import CreditCard01 from 'src/icons/untitled-ui/duocolor/credit-card-01';
import HistoryIcon from '@mui/icons-material/History';
import Download01Icon from '@untitled-ui/icons-react/build/esm/Download01';
// import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { tokens } from 'src/locales/tokens';
import { useDispatch } from 'react-redux';
import { thunks } from 'src/thunks/routerTabThunks';
import { accountServices } from 'src/api/services/accounts';
import { useNavigate } from 'react-router-dom';
import { cleanupCache } from 'src/utils/cleanUpCache';
import { useTranslation } from 'react-i18next';
import CardMembershipIcon from '@mui/icons-material/CardMembership';

export const AccountPopover = (props) => {
   const { anchorEl, onClose, open, ...other } = props;
   const router = useRouter();
   const auth = useAuth();
   const { user } = auth;
   const { t } = useTranslation();

   const navigate = useNavigate();

   const dispatch = useDispatch();

   const loginFromWix = JSON.parse(localStorage.getItem('loginFromWix'));

   const handleLogout = useCallback(async () => {
      try {
         onClose?.();

         switch (auth.issuer) {
            case Issuer.Amplify: {
               await auth.signOut();
               break;
            }

            case Issuer.Auth0: {
               await auth.logout();
               break;
            }

            case Issuer.Firebase: {
               await auth.signOut();
               break;
            }

            case Issuer.JWT: {
               await auth.signOut();
               break;
            }

            default: {
               console.warn('Using an unknown Auth Issuer, did not log out');
            }
         }

         router.push(paths.index);

         accountServices.post.logout();

         cleanupCache();

         navigate('/login');
      } catch (err) {
         console.error(err);
         toast.error('Something went wrong!');
      }
   }, [auth, router, onClose, navigate]);

   return (
      <Popover
         anchorEl={anchorEl}
         anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
         }}
         disableScrollLock
         onClose={onClose}
         open={!!open}
         PaperProps={{ sx: { width: 300 } }}
         {...other}
      >
         <Box sx={{ p: 2 }}>
            <Typography variant="body1">{user?.user?.name}</Typography>
            <Typography color="text.secondary" variant="body2">
               {user?.user?.email}
            </Typography>
         </Box>
         <Divider />
         <Box sx={{ p: 1 }}>
            <ListItemButton
               component={RouterLink}
               href={paths.dashboard.profile}
               onClick={() => {
                  dispatch(thunks.setAccountTab('profile'));
                  onClose();
               }}
               sx={{
                  borderRadius: 1,
                  px: 1,
                  py: 0.5,
               }}
            >
               <ListItemIcon>
                  <SvgIcon fontSize="small">
                     <User03Icon />
                  </SvgIcon>
               </ListItemIcon>
               <ListItemText
                  primary={<Typography variant="body1">{t(tokens.nav.profile)}</Typography>}
               />
            </ListItemButton>
            {!loginFromWix && (
               <ListItemButton
                  component={RouterLink}
                  href={paths.dashboard.profile}
                  onClick={() => {
                     dispatch(thunks.setAccountTab('payments'));
                     onClose();
                  }}
                  sx={{
                     borderRadius: 1,
                     px: 1,
                     py: 0.5,
                  }}
               >
                  <ListItemIcon>
                     <SvgIcon fontSize="small">
                        <CreditCard01 />
                     </SvgIcon>
                  </ListItemIcon>
                  <ListItemText
                     primary={<Typography variant="body1">{t(tokens.nav.topUpBalance)}</Typography>}
                  />
               </ListItemButton>
            )}
            <ListItemButton
               component={RouterLink}
               href={paths.dashboard.profile}
               onClick={() => {
                  dispatch(thunks.setAccountTab('history'));
                  onClose();
               }}
               sx={{
                  borderRadius: 1,
                  px: 1,
                  py: 0.5,
               }}
            >
               <ListItemIcon>
                  <SvgIcon fontSize="small">
                     <HistoryIcon />
                  </SvgIcon>
               </ListItemIcon>
               <ListItemText
                  primary={<Typography variant="body1">{t(tokens.nav.orderHistory)}</Typography>}
               />
            </ListItemButton>
            <ListItemButton
               component={RouterLink}
               href={paths.dashboard.profile}
               onClick={() => {
                  dispatch(thunks.setAccountTab('payments'));
                  onClose();
               }}
               sx={{
                  borderRadius: 1,
                  px: 1,
                  py: 0.5,
               }}
            >
               <ListItemIcon>
                  <SvgIcon fontSize="small">
                     <Download01Icon />
                  </SvgIcon>
               </ListItemIcon>
               <ListItemText
                  primary={<Typography variant="body1">{t(tokens.nav.downloadInvoice)}</Typography>}
               />
            </ListItemButton>
            {/* <ListItemButton
               component={RouterLink}
               href={paths.dashboard.profile}
               onClick={() => {
                  dispatch(thunks.setAccountTab('sla'));
                  onClose();
               }}
               sx={{
                  borderRadius: 1,
                  px: 1,
                  py: 0.5,
               }}
            >
               <ListItemIcon>
                  <SvgIcon fontSize="small">
                     <AssignmentTurnedInIcon />
                  </SvgIcon>
               </ListItemIcon>
               <ListItemText
                  primary={<Typography variant="body1">{t(tokens.nav.sla)}</Typography>}
               />
            </ListItemButton> */}
            <ListItemButton
               component={RouterLink}
               href={paths.dashboard.profile}
               onClick={() => {
                  dispatch(thunks.setAccountTab('subscription'));
                  onClose();
               }}
               sx={{
                  borderRadius: 1,
                  px: 1,
                  py: 0.5,
               }}
            >
               <ListItemIcon>
                  <SvgIcon fontSize="small">
                     <CardMembershipIcon />
                  </SvgIcon>
               </ListItemIcon>
               <ListItemText
                  primary={<Typography variant="body1">Subscription Management</Typography>}
               />
            </ListItemButton>
         </Box>
         <Divider sx={{ my: '0 !important' }} />
         <Box
            sx={{
               display: 'flex',
               p: 1,
               justifyContent: 'center',
            }}
         >
            <Button variant="contained" onClick={handleLogout} size="small">
               {t(tokens.nav.logout)}
            </Button>
         </Box>
      </Popover>
   );
};

AccountPopover.propTypes = {
   anchorEl: PropTypes.any,
   onClose: PropTypes.func,
   open: PropTypes.bool,
};

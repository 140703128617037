import axios from 'src/utils/instantAxios.js';

export const profileServices = {
   get: {
      getProfileInformation: () => axios.get('/api/profile'),
      getMigrationSupported: () => axios.get('/api/subscriptions/migration-supported')
   },
   post: {
      updateProfile: (payload) => axios.post('/api/auth/update', { ...payload }),
      changePassword: (payload) => axios.post(`/api/profile/change-password`, { ...payload }),
   },
};
